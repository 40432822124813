import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import Layout from "../components/layout"
import CollectionPreview from '../components/collection-preview'

class PaintIndex extends React.Component {
  render() {
    const siteMetadata = get(this, 'props.data.site.siteMetadata')
    const collectionLists = get(this, 'props.data.allContentfulPaintCollectionList.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    var collections = []
    if(collectionLists.length > 0) {
      collections = collectionLists[0].node.collections
    }

    return (
      <div>
        <Helmet>
          <title>{`PAINT | ${siteMetadata.title}`}</title>
          <meta name="description" content={siteMetadata.description} />
          <meta name="keywords" content={siteMetadata.keywords} />
          <meta name="author" content={siteMetadata.author} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/*
          <meta property="og:url" content={ogUrl} />
          */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={siteMetadata.title} />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:image" content={siteMetadata.image} />
        </Helmet>

        <Header data={author.node} />
        <div className="wrapper">
          <h2 className="hidden">Play</h2>
          <h3>Collections</h3>
          <ul className="article-list">
            {collections.map(node => {
              return (
                <li key={node.title}>
                  <CollectionPreview collection={node} />
                </li>
              )
            })}
          </ul>
        </div>
        <Footer data={author.node} />
      </div>
    )
  }
}

export default PaintIndex

export const pageQuery = graphql`
  query PaintIndexQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
        keywords
        author
      }
    }

    allContentfulPaintCollectionList(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          collections {
            title
            slug
            coverImage {
              url
              secure_url
            }
            description {
              json
            }
          }
        }
      }
    }

    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          instagram
          email
        }
      }
    }
  }
`
