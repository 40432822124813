import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { addCloudinaryTransformPreset } from '../lib/util'

import styles from './collection-preview.module.css'

export default ({ collection }) => (
  <div className={styles.preview}>
    <Link to={`/paint/${collection.slug}`}>
      <img alt={collection.title} className={styles.previewImage}
        src={addCloudinaryTransformPreset(collection.coverImage[0].secure_url, 'project_cover')} />
      <h3 className={styles.previewTitle}>
        {collection.title}
      </h3>
    </Link>
  </div>
)
